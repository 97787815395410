



















































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserKYC } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchSetupOTP } from '@/store/main/actions';
import { dispatchGetOtpInfo } from '@/store/main/actions';
import { dispatchGenerateOtp } from '@/store/main/actions';
import { dispatchGetOtpQrcode } from '@/store/main/actions';
import { readOtpInfo, readOtpQrcode } from '@/store/main/getters';

const { createHash } = require('crypto');


@Component
export default class UserProfileEdit extends Vue {
  public valid = true;

  // public form = new FormData();
  public form_tmp: string = '';
  public public_key: string = '';

  get otpInfo() {
    return readOtpInfo(this.$store);
  }

  get otpQrcode() {
    return readOtpQrcode(this.$store);
  }

  async mounted() {
    await dispatchGenerateOtp(this.$store);
    await dispatchGetOtpInfo(this.$store);
    await dispatchGetOtpQrcode(this.$store);
  }

  public publicKeyRules() {
    return [(v) => /^\d{6}$/.test(v) || '請輸入 6 碼驗證碼']
  }

  public created() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.form_tmp = userProfile.email;
    }


    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get hashedEmail() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      var hash = createHash('sha256').update(userProfile.email).digest('hex');

      // console.log(hash);

      return hash;
    }
    else return 0;
  }

  // public reset() {
  //   const userProfile = readUserProfile(this.$store);
  //   if (userProfile) {
  //     this.fullName = userProfile.full_name;
  //     this.birthday = '1990/1/1';
  //     this.phone_number = '';
  //     this.physical_address = '';
  //     this.ID_number = '';
  //     this.U_source =  [];
  //     this.checkbox1 = false;
  //     this.checkbox2 = false;
  //   }
  // }

  // public cancel() {
  //   this.$router.back();
  // }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      // console.log(this.public_key);
      // console.log(typeof(this.public_key));
      await dispatchSetupOTP(this.$store, {public_key: this.public_key});
      this.$router.push('/account');
      // this.$router.go(-1);
      // window.location.reload();
    }
  }

  // public addressRules() {
  //   return [(v) => (v.startsWith('T') || v.startsWith('0x')) || '地址應以 T 或 0x 開頭',]
  // }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}
